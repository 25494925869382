<template>
  <div id="user-browse">
    <Portal to="action-bar">
      <ItemActions
        v-if="!isLoading"
        item="user"
        :columns.sync="columns"
        :group-by.sync="groupBy"
        :order.sync="sortBy.order"
        :filter-by.sync="filterBy"
        :active-view.sync="activeView"
        :criteria.sync="sortBy.criteria"
        :import-data="true"
        :data-imported="dataImported"
        form-id=""
        @refresh="getUsers"
        @search="searchRow"
        @update="getUsers"
        @export="export_Data"
        @upload="$refs.inputUser.click()"
        @close="dataImported = false"
        @downloadTemplate="downloadTemplate"
      />
    </Portal>

    <div v-if="!isLoading" class="content">
      <template v-if="totalItems">
        <ItemActionChips
          :sort-by="sortBy"
          :columns="columns"
          :group-by="groupBy"
          :filter-by="filterBy"
          @clearSortBy="clearSortBy"
          @clearGroupBy="clearGroupBy"
          @clearFilterBy="clearFilterBy"
        />

        <input
          ref="inputUser"
          type="file"
          accept=".csv,.xls,.xlsx"
          class="hidden"
          @change="importUserData"
        />

        <UserGrid
          v-if="activeView === 'GRID'"
          :mode="mode"
          :items="items"
          :columns="columns"
          @edit="editUser"
          @more="showUserDetails"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
        />

        <UserList
          v-if="activeView === 'LIST'"
          :mode="mode"
          :items="items"
          :columns="columns"
          @edit="editUser"
          @more="showUserDetails"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
        />
      </template>
      <template v-else>
        <StateWrapper
          icon="eva-people-outline"
          title="NO DATA"
          description="No data available at the moment"
          style="margin-top: 20px"
        />
      </template>
    </div>

    <Pagination
      v-if="!isLoading"
      :total-items="totalItems"
      :current-page.sync="currentPage"
      :items-per-page.sync="itemsPerPage"
      @loadAll="loadAllUsers"
    />

    <UserDetails
      v-model="isUserDetailsVisible"
      :columns="columns"
      :user="selectedUser"
      @edit="editUser"
      @delete="showDeleteConfirmation"
    />

    <ConfirmDeleteUser
      v-model="isDeleteConfirmationVisible"
      :user="selectedUser"
      @delete="updateUser"
    />

    <ConfirmRestoreUser
      v-model="isRestoreConfirmationVisible"
      :user="selectedUser"
      @restore="updateUser"
    />
  </div>
</template>

<script>
import { startCase, capitalize } from "lodash-es";
import { user } from "@/api/factory.js";
import DataType from "@/constants/data-type.js";
import { Portal } from "portal-vue";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import Pagination from "@/components/common/display/Pagination.vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import ItemActionChips from "@/components/common/display/ItemActionChips.vue";
import UserGrid from "./components/UserGrid.vue";
import UserList from "./components/UserList.vue";
import UserDetails from "./components/UserDetails.vue";
import ConfirmDeleteUser from "./components/ConfirmDeleteUser.vue";
import ConfirmRestoreUser from "./components/ConfirmRestoreUser.vue";
import { exportData } from "@/helpers/export-excel-data";
import axios from "axios";

export default {
  name: "UsersBrowseAndTrash",

  components: {
    Portal,
    StateWrapper,
    ItemActionChips,
    ItemActions,
    UserGrid,
    UserList,
    UserDetails,
    Pagination,
    ConfirmDeleteUser,
    ConfirmRestoreUser,
  },

  props: {
    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      columns: [
        {
          id: this.$nano.id(),
          name: "firstName",
          label: "First Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "lastName",
          label: "Last Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "email",
          label: "Email",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "loginName",
          label: "Username",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "role",
          label: "Role",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "jobTitle",
          label: "Job Title",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "department",
          label: "Department",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "manager",
          label: "Manager",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "countryCode",
          label: "Country Code",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "phoneNo",
          label: "Phone No",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "groups",
          label: "Groups",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "secondaryEmail",
          label: "Secondary Email",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdBy",
          label: "Created By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdAt",
          label: "Created At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE_TIME,
        },
        {
          id: this.$nano.id(),
          name: "modifiedBy",
          label: "Modified By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "modifiedAt",
          label: "Modified At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE_TIME,
        },
      ],
      users: [],
      userData: [],
      activeView: "GRID",
      selectedUser: {},
      isUserDetailsVisible: false,
      isDeleteConfirmationVisible: false,
      isRestoreConfirmationVisible: false,
      isLoading: false,
      sortBy: {
        criteria: "",
        order: "DESC",
      },
      groupBy: "",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 50,
      currentPage: 1,
      dataImported: false,
    };
  },

  computed: {
    items() {
      return this.users.map(({ key, value }) => ({
        key: this.groupBy,
        value: key,
        data: value.map((user) => ({
          id: user.id,
          firstName: user.firstName || "",
          lastName: user.lastName || "",
          email: user.email,
          loginName: user.loginName,
          role: startCase(capitalize(user.role)),
          jobTitle: user.jobTitle,
          department: user.department,
          manager: user.manager?.value,
          countryCode: user.countryCode,
          phoneNo: user.phoneNo,
          groups: user.groups || [],
          secondaryEmail: user.secondaryEmail,
          avatar: `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIwRBD9gNuA2GjcOf6mpL-WuBhJADTWC3QVQ&usqp=CAU`,
          createdBy: user.createdBy,
          createdAt: this.$day.format(user.createdAt),
          modifiedBy: user.modifiedBy,
          modifiedAt: this.$day.format(user.modifiedAt),
        })),
      }));
    },
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.defaultView();
      },
    },

    "$store.state.defaultView": function () {
      this.defaultView();
    },
  },

  mounted() {
    this.$watch(
      (vm) => [vm.sortBy, vm.groupBy, vm.currentPage, vm.itemsPerPage],
      () => this.getUsers(),
      { deep: true, immediate: true }
    );
  },

  methods: {
    defaultView() {
      let options = this.$store.state.defaultView;
      let menu = this.$route.meta.menu;
      let subMenu = this.$route.meta.breadcrumb;
      if (Object.keys(options).length) {
        if (options[menu]) {
          let view = options[menu].find((item) => item.subMenu === subMenu);
          if (view) {
            this.activeView = view.view;
          }
        }
      }
    },

    clearSortBy() {
      this.sortBy = {
        criteria: "",
        order: "DESC",
      };
    },

    clearGroupBy() {
      this.groupBy = "";
    },

    clearFilterBy(id, index) {
      this.filterBy[id].filters.splice(index, 1);
      if (this.filterBy[id].filters.length == 0) {
        this.filterBy[id].filters = [];
        this.filterBy.splice(id, 1);
      }
      if (this.filterBy.length == 0) {
        this.filterBy = [];
      }
      this.getUsers();
    },

    findUser(userId) {
      const users = [];
      this.items.forEach((item) => users.push(...item.data));
      return users.find((user) => user.id === userId);
    },

    showUserDetails(userId) {
      this.selectedUser = this.findUser(userId);
      this.isUserDetailsVisible = true;
    },

    editUser(userId) {
      this.$router.push({
        name: "user-builder",
        params: { id: String(userId) },
      });
    },

    showDeleteConfirmation(userId) {
      this.selectedUser = this.findUser(userId);
      this.isDeleteConfirmationVisible = true;
    },

    showRestoreConfirmation(userId) {
      this.selectedUser = this.findUser(userId);
      this.isRestoreConfirmationVisible = true;
    },

    loadAllUsers() {
      this.itemsPerPage = this.totalItems;
      this.currentPage = 1;
    },

    searchRow(search) {
      if (search) {
        this.users = this.userData.map(({ key, value }) => ({
          key: key,
          value: value.filter((row) => {
            for (let cell in row) {
              let index = this.columns.findIndex((column) => {
                return column.name === cell;
              });
              if (index >= 0 && row[cell]) {
                if (
                  String(row[cell]).toLowerCase().includes(search.toLowerCase())
                ) {
                  return row;
                }
              }
            }
          }),
        }));
      } else {
        this.users = this.userData;
      }
    },

    /* api functions */

    async getUsers() {
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await user.getUsers({
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;

      this.users = data || [];
      this.userData = data || [];
      this.totalItems = meta?.totalItems || 0;
    },

    async updateUser(payload) {
      this.$store.commit("showLoadingBarPage");

      const { error } = await user.updateUser(this.selectedUser.id, payload);

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const mode = payload.isDeleted ? "deleted" : "restored";
      const name =
        this.selectedUser.firstName + " " + this.selectedUser.lastName;
      this.$alert.success(`User "${name}" ${mode} successfully`);

      this.getUsers();
    },

    async export_Data() {
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await user.getUsers({
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: this.filterBy,
        itemsPerPage: 0,
        currentPage: 0,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { meta, data } = payload;
      if (meta.totalItems && data.length) {
        let exportRecords = [];
        exportRecords = data[0].value.map((user) => ({
          "First Name": user.firstName || "",
          "Last Name": user.lastName || "",
          Email: user.email,
          Username: user.loginName,
          Role: startCase(capitalize(user.role)),
          "Job Title": user.jobTitle,
          Department: user.department,
          Manager: user.manager?.value,
          "Country Code": user.countryCode,
          "Phone Number": user.phoneNo,
          Groups: user.groups || "",
          "Secondary Email": user.secondaryEmail,
          "Created By": user.createdBy,
          "Created At": this.$day.format(user.createdAt),
          "Last Modified By": user.modifiedBy,
          "Last Modified At": this.$day.format(user.modifiedAt),
        }));
        let exportName = "User List - Report";
        if (this.mode === "TRASH") {
          exportName = "User Trash List - Report";
        }
        exportData(exportRecords, exportName);
      } else {
        //this.$alert.info("No data found for export");
      }
    },

    async importUserData(e) {
      const file = e.target.files[0];
      if (
        file.type === "text/csv" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel"
      ) {
        const formData = new FormData();

        formData.append("file", file);
        try {
          this.$store.commit("showLoadingBarSave");
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/user/importUser`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          this.$store.commit("hideLoadingBarSave");
          const { status, data } = response;
          // console.log(data);
          if (status === 200 && data)
            this.$alert.success(`Data imported successfully`);
          else throw response;
        } catch (error) {
          this.$alert.error("Error uploading file");
          console.error(error);
        }
        this.dataImported = true;
        e.target.value = "";
        this.getUsers();
      } else {
        this.$alert.error("Please upload a csv/excel file");
      }
    },

    downloadTemplate() {
      let exportRecords = [];
      exportRecords = [
        {
          "First Name": "",
          "Last Name": "",
          Email: "",
          Username: "",
          Role: "",
          "Job Title": "",
          Department: "",
          Manager: "",
          "Country Code": "",
          "Phone Number": "",
          Groups: "",
          "Secondary Email": "",
          "Created By": "",
          "Created At": "",
          "Last Modified By": "",
          "Last Modified At": "",
        },
      ];
      let exportName = "User Template";

      exportData(exportRecords, exportName);
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#user-browse {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 248px);
  }
}
</style>
